interface IsMarriedInfo {
    isMarried: boolean
}

enum IsMarriedActionTypes {
    setIsMarried = "SET_IS_MARRIED"
}

type isMarriedActionTypesStrings = typeof IsMarriedActionTypes[keyof typeof IsMarriedActionTypes];

interface ISetMarried {
    type: isMarriedActionTypesStrings;
    payload: boolean | number;
}

const initialState: IsMarriedInfo = {
    isMarried: false,
};

export const marriedInfoReducer = (state = initialState, action: ISetMarried) => {
    switch (action.type) {
        case IsMarriedActionTypes.setIsMarried: {
            return {
                ...state,
                isMarried: action.payload,
            };
        }
        default:
            return {
                ...state,
            };
    }
};
