import React from "react";
import { useFela } from "react-fela";
import { radioGroup, radioButton, radioRatingLabel, dnone } from "./styles";

interface Iprops {
    options?: { value: number, label: string },
    onSubmit: (arg0: number) => void;
}

export const RadioGroupStatus = React.memo((props: Iprops) => {
    const { css } = useFela();
    const [currentRatingMarriage, setCurrentRatingMarriage] = React.useState<Number | undefined>(undefined);

    const onRatingChangeMarriage = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rating = parseInt(e.target.value);
        setCurrentRatingMarriage(rating);
        props.onSubmit(rating);
    };

    const renderOptions = () => {
        const options: JSX.Element[] = [];
        for (let i = 1; i >= 0; i--) {
            options.push(
                <div className={css(radioButton)} key={i}>
                    <input
                        type="radio"
                        id={`ratingMarriage-${i}`}
                        name={`ratingMarriage-${i}`}
                        value={i}
                        checked={currentRatingMarriage === i}
                        className={css(dnone)}
                        onChange={onRatingChangeMarriage}>
                    </input>
                    <label
                        htmlFor={`ratingMarriage-${i}`}
                        id={"marriage"}
                        className={css(radioRatingLabel)}>
                        <div className="label-dot"></div>
                        <span className="label-text">
              { i ? "Verheiratet" : "Ledig" }
            </span>
                    </label>
                </div>
            );
        }
        return options;
    }

    return (
        <div className={`${css(radioGroup)} ml-36`}>
            {renderOptions()}
        </div>
    );
});
